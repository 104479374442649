export const WSChannels = {
  JobsChannel: "JobChannel",
  CandidateChannel: "CandidateChannel",
  EmployerChannel: "EmployerChannel",
  HrCompanyChannel: "HrCompanyChannel",
  AppChannel: "AppChannel",
  LoginChannel: "LoginChannel",
  NewJobChannel: "JobChannel",
  AdminChannel: "AdminDashboardChannel",
  SchedulerChannel: "CalendarEventChannel",
  SettingsChannel: "UserChannel",
  NotificationChannel: "NotificationChannel"
};
